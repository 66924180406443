import React from 'react';
import { Layout, Typography } from 'antd';
import { useSelector } from 'react-redux';

const Home = ({ userData }) => {
  const user = useSelector(
		(state) => state.customer.customerClaims
	);
  const currentDate = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Australia/Sydney'
}).format(new Date());

  return (
    <Layout style={{backgroundColor:'white'}}>
    <div className="container d-flex flex-column align-items-center justify-content-center vh-65">
      <Typography.Title level={3} className=" fst-italic text-center mb-3 text-primary fw-bold " >
        Welcome to the Admin Portal, <span className='text-capitalize'>{user?.name || 'Admin'}</span> 
      </Typography.Title>
      <Typography.Text type="secondary" className="fst-italic text-center mb-3">
        {currentDate}
      </Typography.Text>
    </div>
  </Layout>
  );
};

export default Home;
