import { endpointReceiptValidation } from "../firebase/endpoints";
import axios from "axios";
import { handlerAxiosErrors } from "lib/helpers/helperFunctions";
import { axiosConfig } from "lib/constants/axiosConstant";

export const validateSubscriptionPlan = async (transactionReceipt) => {
  const requestBody = {
    sub: transactionReceipt,
  };
  let validateSubscription = await axios
    .post(endpointReceiptValidation, requestBody, axiosConfig)
    .catch((err) => handlerAxiosErrors(err));
  return validateSubscription;
};
