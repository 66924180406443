import firebase from "./firebase";
import { db } from "./db";
import { logMessage } from "lib/helpers/helperFunctions";

const auth = firebase.auth();
if (process.env.REACT_APP_EMULATOR === 'true') {
	auth.useEmulator('http://localhost:9099');
}

export { auth };

export function loginUser(email, password) {
	return new Promise((resolve, reject) => {
		auth
			.signInWithEmailAndPassword(email, password)
			.catch((error) => {
				reject(error.message);
			})
			.then((user) => {
				if (user) {
					resolve(user);
				}
			});
	});
}

export function logoutUser() {
	auth
		.signOut()
		.then(() => {
			logMessage('User signed out!');
		})
		.catch((error) => {
			logMessage(error);
		});
}

export function userStatus() {
	return auth.onAuthStateChanged((user) => {
		if (user !== null) {
			return true;
		} else {
			return false;
		}
	});
}

export async function getUserDetails(user) {
	const uid = user.uid;
	const data = db.collection('users').doc(uid);
	const userData = await data.get().then((doc) => {
		if (doc.exists) {
			return doc.data();
		} else {
			return false;
		}
	});
	return userData;
}

export const checkIfUserIsAdmin = async () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (
          idTokenResult.claims.admin === 1 ||
          idTokenResult.claims.admin === 2
        )
          resolve(idTokenResult.claims);
        else reject("Not Admin");
      })
      .catch((err) => reject("admin"));
  });
};

export const getAuthorizedUser = () => {
  let user = {
    email: auth.currentUser.email,
    uid: auth.currentUser.uid,
  };
  return user;
};

