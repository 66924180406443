import {
  getClaimInfo,
  updateAdminStatus,
  updateUserEmail,
  updateUserPassword,
} from "../../lib/firebase/functions";
import { updateUser } from "lib/firebase/db";
import firebase from "firebase";
import {
  logMessage,
  showErrorToast,
  showSuccessMessage,
  formatDate,
} from "lib/helpers/helperFunctions";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EditableText from "components/EditableTextComponent";
import { ADMIN, USER, SUPER_ADMIN } from "lib/constants/userTypes";
import { Modal, Button, Form } from "react-bootstrap";
import { getUpdateUserData } from "lib/firebase/db";
import { useDispatch } from "react-redux";
import { setCustomer } from "features/customer/customerSlice";
import { Spin} from "antd";

const CustomerDetails = (props) => {
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    email,
    stripeCustomer,
    transactionReceipt,
    phoneNumber,
    created,
  } = useSelector((state) => state.customer.customer);

  const { customerClaims } = useSelector((state) => state.customer);
  const customerUid = useSelector((state) => state.customer.customerUid);
  const [formSwitch, setformSwitch] = useState(false);
  const [userFirstName, setFirstName] = useState(firstName);
  const [userLastName, setUserLastName] = useState(lastName);
  const [userEmail, setUserEmail] = useState(email);
  const [userPhoneNumber, setUserPhoneNumber] = useState(phoneNumber);
  const [userCreatedAt, setUserCreatedAt] = useState(formatDate(created));
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [switchVisible, setSwitchVisible] = useState(false);
  const [modalForChangePassword, setModalForChangePassword] = useState(false);
  const [subscriptionMode, setSubscriptionMode] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [switchText, setSwitchText] = useState("");
  const [loadingText, setLoadingText] = useState(false);

  useEffect(() => {
    setFirstName(firstName);
    setUserLastName(lastName);
    setUserEmail(email);
    setUserPhoneNumber(phoneNumber);
    setUserCreatedAt(formatDate(created));
    getAdminStatus();
    handlePermissionToChangeAdmin(customerClaims);
    findService();
  }, [customerUid]);
  const toggleSwitch = (formSwitch) => {
    setLoadingText(true);
    handleSubmit(formSwitch);
  };
  const handleSubmit = (formSwitch) => {
    if (customerClaims?.admin === SUPER_ADMIN) {
      let adminStatus = formSwitch ? ADMIN : USER;
      updateAdminStatus(customerUid, adminStatus)
        .then((result) => {
          showSuccessMessage(
            `Admin Access has been ${
              formSwitch ? "granted to" : "revoked from"
            } ${firstName}`
            );
            setformSwitch(formSwitch);
            setLoadingText(false);
        })
        .catch((err) => {
          setLoadingText(false);
          logMessage(err);
        });
      } else {
      setLoadingText(false);
      showErrorToast("You do not have permission to update this!");
    }
  };

  const findService = () => {
    if (transactionReceipt?.startsWith("sub")) setSubscriptionMode("Stripe");
    else if (typeof transactionReceipt == "string")
      setSubscriptionMode("Apple App Store");
    else setSubscriptionMode("Google Play Store");
  };

  const handleEmailInput = (email) => setUserEmail(email);
  const handleFirstNameInput = (firstName) => setFirstName(firstName);
  const handleLastNameInput = (lastName) => setUserLastName(lastName);
  const handlePhoneNumberInput = (phoneNumber) =>
    setUserPhoneNumber(phoneNumber);

  const handleCreatedAtInput = (createdAt) => setUserCreatedAt(createdAt);

  const handlePhoneValidationInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9+]/, "").slice(0, 12);
  };

  const handleFirstNameUpdate = () => {
    if (userFirstName !== firstName)
      updateUser(customerUid, "firstName", userFirstName);
  };
  const handleLastNameUpdate = () => {
    if (userLastName !== lastName)
      updateUser(customerUid, "lastName", userLastName);
  };
  const handlePhoneNumberUpdate = () => {
    if (userPhoneNumber !== phoneNumber)
      updateUser(customerUid, "phoneNumber", userPhoneNumber);
  };

  const handleCreatedAtUpdate = () => {
    if (userCreatedAt !== created)
      updateUser(
        customerUid,
        "created",
        firebase.firestore.Timestamp.fromDate(new Date(userCreatedAt))
      );
  };

  const getAdminStatus = () => {setLoadingText(true);
  getClaimInfo(customerUid).then((res) => handleSwitchStatus(res))};

  const handleSwitchStatus = (adminStatus) => {
    if (!adminStatus?.data?.status || adminStatus?.data?.status === USER) {
      setformSwitch(false);
      setSwitchDisabled(false);
      setSwitchText("Admin");
      setLoadingText(false);
    } else {
      setSwitchText(
        adminStatus?.data?.status === SUPER_ADMIN ? "Super Admin" : "Admin"
      );
      setSwitchDisabled(
        adminStatus?.data?.status === SUPER_ADMIN ? true : false
      );
      setformSwitch(true);
      setLoadingText(false);
    }
  };
  const handlePermissionToChangeAdmin = (customerClaims) => {
    if (customerClaims?.admin === SUPER_ADMIN) setSwitchVisible(true);
  };

  const handleEmailUpdate = () =>
    updateUserEmail(
      customerUid,
      stripeCustomer,
      email,
      userEmail,
      subscriptionMode
    ).then(() => {
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    });

  const toggleModalForChangePassword = () =>
    setModalForChangePassword(!modalForChangePassword);

    const handleChangePassword = () => {
      setIsLoading(true);
      if (customerClaims?.admin === SUPER_ADMIN || customerClaims?.admin === ADMIN ) {
      updateUserPassword(customerUid, newPassword).then((res) => {
        setIsLoading(false);
        if (res?.data?.status === "error") {
          showErrorToast(res.data.message);
        } else {
          showSuccessMessage("Password is Updated");
          toggleModalForChangePassword();
        }
        logMessage(res);
      });
    } else {
      setIsLoading(false);
      showErrorToast("You do not have permission to update this!");
    }
    };
  return (
    <div className="container align-center text-align-center rounded bg-white mt-3 ml-1 col-md-8 col-lg-8">
      <div className="mt-4">
        {/* <div className="d-flex align-items-center mb-3">
        <Text>User Id :- </Text> <Title level={5} type="secondary" copyable>{customerUid}</Title>
        </div> */}
        <div className="row mt-2">
          <EditableText
            className="col-md-12 col-lg-6"
            text={userFirstName}
            label={"First Name"}
            placeholder="First Name"
            setValue={handleFirstNameUpdate}
            onChange={(e) => handleFirstNameInput(e.target.value)}
          />
          <EditableText
            className="col-md-12 col-lg-6"
            text={userLastName}
            label={"Last Name"}
            placeholder="Last Name"
            setValue={handleLastNameUpdate}
            onChange={(e) => handleLastNameInput(e.target.value)}
          />
        </div>
        
        <div className="row mt-2">
          <EditableText
            text={userEmail}
            className="col-md-12 col-lg-6"
            label={"Email"}
            placeholder="Email"
            type="email"
            setValue={handleEmailUpdate}
            onChange={(e) => handleEmailInput(e.target.value)}
          />

          <EditableText
            text={userPhoneNumber}
            label={"Phone Number"}
            placeholder="Phone Number"
            className="col-md-12 col-lg-6 no-spinner"
            type="text"
            onInput={handlePhoneValidationInput}
            setValue={handlePhoneNumberUpdate}
            onChange={(e) => handlePhoneNumberInput(e.target.value)}
          />
        </div>

        <div className="row mt-2">
          <EditableText
            text={userCreatedAt}
            className="col-md-12 col-lg-6 created-input"
            label={"Created at"}
            placeholder="Creation Date"
            type="datetime-local"
            setValue={handleCreatedAtUpdate}
            onChange={(e) => handleCreatedAtInput(e.target.value)}
          />
        </div>
  
      </div>
      
      <div>
        {switchVisible && (
          <div className="col-md-12 col-md-6 mt-4 form-check form-switch">
            {loadingText ? (
              <Spin />
            ) : (
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                {switchText}
              </label>
            )}

            <input
              disabled={switchDisabled}
              onChange={() => toggleSwitch(!formSwitch)}
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={formSwitch}
            />
          </div>
        )}
        <div className="mt-4">

        <Button
          onClick={toggleModalForChangePassword}
          className="non-focus-btn "
          variant="primary"
          >
          Update Password
        </Button>
          </div>
      </div>
      {/* Modal start */}
      <Modal
        onHide={toggleModalForChangePassword}
        centered
        show={modalForChangePassword}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            className="me-auto"
            placeholder="Add new password here..."
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {newPassword.length < 8 ? (
            <span className="text-danger">
              Password must be atleast 8 characters long
            </span>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleChangePassword}
            className="non-focus-btn"
            variant={isLoading ? "Light" : "primary"}
            type="submit"
            disabled={newPassword.length < 8 ? true : false}
          >
            {isLoading ? "Updating..." : "Update"}
          </Button>
          <Button
            className="non-focus-btn"
            onClick={toggleModalForChangePassword}
            variant="danger"
          >
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal end */}
    </div>
  );
};

export default CustomerDetails;
