import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Typography, Alert, Button } from 'antd';
import Papa from 'papaparse';
import { CopyOutlined } from '@ant-design/icons';
import { blockUsers, deleteUsersPermanent } from '../../lib/firebase/functions';
import { useParams } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import Breadcrumbs from '../shared/breadcrumb';
const { Title } = Typography;

const DeleteOrBlockUsers = () => {
  let { operation } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const doneStatusRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
  };
  const [data, setData] = useState([]);
  const [getInvalidEmailsArray, setInvalidEmailsArray] = useState([]);
  const [shouldContinueUpload, setShouldContinueUpload] = useState(true);
  const breadcrumbItems = [
    { label: 'Dashboard', href: '/' },
    { label: 'Super-Admin', href: '/super-admin' },
    { label: `${operation === 'block' ? 'Block User' : 'Delete User'}` },
  ];


  const handleFileChange = (file) => {
    if (!shouldContinueUpload) return;
    Papa.parse(file, {
      header: true,
      complete: ({ data }) => {
        const allHaveEmailKey = data[0].hasOwnProperty('email');
        if (!allHaveEmailKey) {
          alertMessage('error', "Uploaded file missing 'email' Field.");
          setData([]);
          return;
        }


        const validEmailsArray = data
          .filter(item => 'email' in item && isValidEmail(item.email))
          .map(item => item.email.trim())
          .filter(email => email);

        const invalidEmailsArray = data
          .filter(item => 'email' in item && !isValidEmail(item.email))
          .map(item => item.email.trim())
          .filter(email => email);

        setInvalidEmailsArray(invalidEmailsArray);
        setData(validEmailsArray);
      }
    });
  }

  const props = {
    name: `file`,
    multiple: false,
    showUploadList: shouldContinueUpload,
    accept: ".csv",
    maxCount: 1,
    beforeUpload: (file) => {
      setData([])
      const csvFormat = file.type === 'text/csv';
      const isLt2M = file.size / 1024 / 1024 < 0.05;
      if (!csvFormat) {
        alertMessage('error', "Only CSV and XML files are supported.");
        return false;
      }
      else if (!isLt2M) {
        alertMessage('error', 'File must smaller than 50KB!');
        return false;
      }
      else {
        setShouldContinueUpload(true);
        return true
      }
    },
    onChange: info => {
      const nextState = {};
      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFile = info.file;
          nextState.selectedFileList = [info.file];
          handleFileChange(info.file.originFileObj);
          break;

        default:
          nextState.selectedFile = null;
          nextState.selectedFileList = [];
          setData([]);

      }
    },
    customRequest: doneStatusRequest,
    onDrop(e) {
      handleFileChange(e.dataTransfer.files[0]);
    },
    progress: {
      width: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onRemove: () => {
      setData([]);
    }
  };

  const blockOrDeleteUnauthorizedUsers = () => {
    if (getInvalidEmailsArray.length === 0) {
      setShowLoader(true);
      const apiCall = operation === 'block' ? blockUsers(data) : deleteUsersPermanent(data);
      apiCall.then(resultData => {
        alertMessage('success', resultData.data.message);
      }).catch(err => {
        console.error(err);
        alertMessage('error', "Something went wrong! Please try again.");
      }).finally(() => {
        setShowLoader(false);
        setData([]);
        setInvalidEmailsArray([]);
      });
    }
  };

  //  Utility functions

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const alertMessage = (type, content) => {
    messageApi.open({
      type,
      content,
      style: {
        marginTop: '5vh',
      },
    });
    setShouldContinueUpload(false);
  }

  // HTML template starts 
  return (
    <>
      {contextHolder}
      <div className='container'>
        {getInvalidEmailsArray.length > 0 && (
          <Alert
            message={
              <>
                <strong>Invalid Emails found, Correct them before Submit request </strong>
              </>
            }
            description={
              <>
                {getInvalidEmailsArray.join('\n').substring(0, 150) + (getInvalidEmailsArray.length > 150 ? "..." : "")}
                <CopyOutlined title="Copy to Clipboard" onClick={() => copyToClipboard(getInvalidEmailsArray.join('\n'))} style={{ cursor: 'pointer', marginLeft: '5px' }} />
              </>
            }

            type="error"
            showIcon
            closable
          />
        )}

        {showLoader && (<div className="loader"></div>)}
          <Breadcrumbs items={breadcrumbItems} className="mt-4"/>
      
        <div style={{ display: 'flex', flexDirection: 'column' }} >

          <Title  className="dropBox_upload_title text-primary fw-semibold " level={2}> Upload File To {operation === 'block' ? 'Block' : 'Delete'} Unauthorized User</Title>

          <div className="dropBox_btn my-3">
            <Button
              className={'btn-width'}
              disabled={getInvalidEmailsArray.length > 0 || !data.length}
              size={'large'}
              onClick={blockOrDeleteUnauthorizedUsers}
            >Submit</Button>

          </div>


          <div className='dropBox'>
            <Dragger className="fullScreenDragger" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload CSV format only and file must contain 'email' field with file max size upto 50KB.
              </p>
            </Dragger>
          </div>
        </div>
      </div>

    </>

  )
};
export default DeleteOrBlockUsers;
