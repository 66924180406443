import React, { useState } from "react";
import { Typography } from "antd";
import { Input } from 'antd';

const { TextArea } = Input;

const { Title } = Typography;
const EditableText = ({
  text,
  label,
  className,
  updateHandler,
  setValue,
  name,
  value,
  onChange,
  textarea, 
  type,
  ...props
}) => {
  const [editable, setEditable] = useState(false);

  return (
    <div className={className}>
      <Title level={5} strong>
        {label}
      </Title>
      {editable ? (
        <div className="input-group">
          {textarea ? (
            <TextArea
              {...props}
              autoFocus={editable}
              name={name}
              className="form-control"
              value={text || ''}
              onBlur={() => {
                setEditable(!editable);
                setValue && setValue();
              }}
              onChange={onChange}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          ) : (
            <input
              {...props}
              autoFocus={editable}  
              name={name}
              type
              className="form-control"
              value={text || ''}
              onBlur={() => {
                setEditable(!editable);
                setValue && setValue();
              }}
              onChange={onChange}
            />
          )}
          <button
            onClick={updateHandler}
            className="btn btn-primary"
            type="button"
          >
            Update
          </button>
        </div>
      ) : (
        <div
          className="editableText"
          onClick={() => {
            setEditable(!editable);
          }}
        >
          <p class="overflow-ellipsis">{text || "N/A"}</p>
        </div>
      )}
    </div>
  );
};

export default EditableText;
