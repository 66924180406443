import React from 'react';
import { Divider, Modal  } from 'antd';
import EditableTable from "./EditableTableComponent";
import Title from 'antd/es/typography/Title';

const ListModel = ({ visible, handleCancel , body ,title, userId , subCollectionName}) => {
  return (
    <Modal 
      title = {<Title level={4} className="text-primary fw-bold mb-0 text-uppercase">{title}</Title>}
      centered
      open={visible}
      onCancel={handleCancel}
      destroyOnClose="true"
      width="60%"
      zIndex={99999}
      footer={null}
    >
      <div>
      <Divider />
           <EditableTable data={body} userId={userId}  subCollectionName={subCollectionName} modelClose={handleCancel}/>
       </div>
    </Modal >
  );
};

export default ListModel;