import React, { useState } from 'react';
import PasswordInput from 'features/authentication/password-input';

export default function PasswordChange(props) {
	const { auth } = props;
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState({ text: '', status: 'waiting' });

	const changePassword = () => {
		const currentUser = auth.currentUser;
		if (currentUser) {
			currentUser
				.updatePassword(password)
				.then(() => {
					setPassword('');
					setMessage({ text: 'Password changed!', status: 'success' });
				})
				.catch((error) => {
					setPassword('');
					setMessage({
						text: error.message,
						status: 'warning'
					});
				});
		}
	};

	return (
		<div
			className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
			style={{ height: 'calc(100vh - 52px)' }}
		>
			<h1 className='login-title h3 mt-2 mb-4'>Change Password</h1>
			<form
				className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
				onSubmit={(e) => {
					e.preventDefault();
					changePassword();
				}}
				aria-describedby='form_error'
			>
				<PasswordInput password={password} setPassword={setPassword} />
				<button type='submit' className='btn btn-primary col-12 mb-3'>
					Submit
				</button>
				{message.status !== 'waiting' && (
					<div className={`alert alert-${message.status}`} role='alert'>
						{message.text}
					</div>
				)}
			</form>
		</div>
	);
}
