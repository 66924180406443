import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "../../lib/firebase/auth";
import firebase from "../../lib/firebase/firebase";

import { useDispatch } from "react-redux";
import { setCustomer, setCustomerId } from "features/customer/customerSlice";
import { logMessage } from "lib/helpers/helperFunctions";
export default function Navigation(props) {
	const dispatch = useDispatch();
	const { auth } = props;
	const searchTypes = [
		{ name: "Email", value: "email" },
		{ name: "First Name", value: "firstName" },
		{ name: "Last Name", value: "lastName" },
	];

	const db = firebase.firestore();

	const [search, setSearch] = useState("");
	const [searchType, setSearchType] = useState(searchTypes[0]);
	const [results, setResults] = useState([]);

	const getUsers = async () => {
		return new Promise((resolve, reject) => {
			db.collection("Users")
				.where(searchType.value, ">=", search)
				.where(searchType.value, "<", search + "z")
				.limit(10)
				.get()
				.then((querySnapshot) => {
					const data = [];
					querySnapshot.forEach((doc) => {
						let userData = doc.data()
						userData.id = doc.id
						data.push(userData);
					});
					if (search === '') {
						resolve([])
					}
					else {
						resolve(data);
					}

				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	// Debounce function
	function useDebounce(value, delay) {
		const [debouncedValue, setDebouncedValue] = useState(value);

		useEffect(() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		}, [value, delay]);

		return debouncedValue;
	}
	const debouncedSearchTerm = useDebounce(search, 300);

	const handleSubmit = async () => {
		setTimeout(() => {
			getUsers()
				.then((result) => setResults(result))
				.catch((error) => logMessage(error));
		}, 0)
	};

	useEffect(() => {
		if (debouncedSearchTerm !== "") {
			handleSubmit();
		}
	}, [debouncedSearchTerm, searchType]);
	return (
		<nav
			className='navbar navbar-light fixed-top border-bottom border-1 bg-primary pt-1 pb-1'
			style={{ minHeight: '59px' }}
		>
			<div className='d-flex header-nav justify-content-between vw-100 ps-3 pe-3'>
				<div></div>
				<div
					className='navbar-brand p-0 m-0 d-flex align-items-center'
					style={{ flex: 1 }}
				>
					<Link to='/'>
						<img
							className='p-0 m-0'
							src='dd_logo.svg'
							alt='Defeat Diabetes - Admin'
							width='160px'
						/>
					</Link>
				</div>
				{auth ? (
					<>
						<div className="search-form py-2">
							<form
								className='d-flex py-1 position-relative'
								style={{ flex: 3 }}
								onSubmit={(event) => {
									event.preventDefault();
								}}
							>
								<div className='input-group'>
									<input
										className='form-control'
										type='search'
										placeholder='Search'
										aria-label='Search'
										value={search}
										onChange={(e) => {
											setSearch(e.target.value);
										}}
									/>
									<button
										className='btn btn-light dropdown-toggle text-primary text-small'
										type='button'
										style={{ zIndex: 0 }}
										id='searchTypeDropdown'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										{searchType.name}
									</button>
									<ul
										className='dropdown-menu dropdown-menu-end'
										aria-labelledby='searchTypeDropdown'
									>
										{searchTypes.map((type) => {
											return (
												<li key={`search-type-item-${type.value}`}>
													<button
														className='dropdown-item text-smaller'
														onClick={() => {
															setSearchType(type);
														}}
													>
														{type.name}
													</button>
												</li>
											);
										})}
									</ul>
									<button className='btn btn-warning' type='submit' style={{ 'zIndex': 'auto' }}>
										<i className='bi-search text-primary'></i>
									</button>
								</div>
								{results.length > 0 && (
									<div className='w-100 top-100' style={{ position: 'absolute' }}>
										<div className='list-group'>
											{results.map((result, index) => {
												return (
													<Link key={index} to='/customer'>
														<button
															className='list-group-item list-group-item-action'
															key={result.email}
															onClick={() => {
																setSearch('');
																dispatch(setCustomer(result));
																dispatch(setCustomerId(result.id));
																setResults([])
															}}
														>
															{`${result.firstName} ${result.lastName} - ${result.email}`}
														</button>
													</Link>
												);
											})}
										</div>
									</div>
								)}
							</form>

						</div>


						<div
							className='ms-auto me-0 d-flex justify-content-end align-items-center'
							style={{ flex: 1 }}
						>
							<nav className='nav header-nav'>
								<div className='dropdown dropdown-search nav-item ms-3'>
									<button
										className='btn'
										style={{ lineHeight: '1rem' }}
										type='button'
										id='dropdownMenuButton1'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										<i
											className='bi-list'
											style={{ fontSize: '1.5rem', color: 'white' }}
										></i>
									</button>
									<ul
										className='dropdown-menu dropdown-menu-end'
										aria-labelledby='dropdownMenuButton1'
									>
										{auth &&
											auth.uid &&
											[
												'csD4Bib8nEdLgoHEIr2qMy444WJ3',
												'GXZuev3bkA3Qf2YKWyli84m18hth',
												'8tfJdLd9RSdAipOAIbfJfFocUMW2',
												'HlxLJj9eknZM84gHX33tpD5nWJE2',
												'apHapYvttDSg5YLwzpqnwMDKKuI3',//ricky uat user
												"lLHKLcjR1yYmND7ZVnKuBsjXqbk2", //prakhar-prod
											].includes(auth.uid) && (
												<li className='' style={{ fontSize: '0.9rem' }}>
													<Link className='dropdown-item' to='/super-admin'>
														Super Admin
													</Link>
												</li>
											)}
										<li className='' style={{ fontSize: '0.9rem' }}>
											<Link className='dropdown-item' to='/password-change'>
												Change Password
											</Link>
										</li>
										<li className='' style={{ fontSize: '0.9rem' }}>
											<button
												className='dropdown-item'
												onClick={() => {
													setSearch('')
													setResults([])
													logoutUser();
												}}
											>
												Logout
											</button>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</>
				) : null}
			</div>
		</nav>
	);
}
