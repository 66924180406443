import { useState } from "react";
import { Link } from "react-router-dom";
import PasswordInput from "features/authentication/password-input";
import { loginUser } from "../lib/firebase/auth";
import { checkIfUserIsAdmin } from "../lib/firebase/auth";
const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = () => {
    loginUser(email, password)
      .then((res) => {
        checkIfUserIsAdmin()
          .then(() => {
            setLoginError(null);
          })
          .catch((err) => {
            setLoginError("Email or Password is incorrect - 2");
          });
      })
      .catch((err) => {
        handleError(err);
        console.log("Promise Response ", err);
      });
  };

  const handleError = (error) => {
    error.includes("Access to this account")
      ? setLoginError(error)
      : setLoginError("Email or Password is Incorrect - 1");
  };
  return (
		<>
			<div
				className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
				style={{ height: 'calc(100vh - 52px)' }}
			>
				<h1 className='login-title h3 mt-2 mb-3 text-primary fw-bold'>
					Admin Login
				</h1>
				<p className='text-small'>
					Enter your admin credentials here to access customer service portal
				</p>
				<form
					className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					aria-describedby='form_error'
				>
					<div className='form-group mb-3'>
						<label htmlFor='emailAddress' className='form-label'>
							Email address
						</label>
						<input
							className='form-control'
							aria-describedby='emailHelp'
							placeholder='eg: john@dailychecklist.com.au'
							type='email'
							id='emailAddress'
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							autoFocus={true}
							required
						/>
					</div>
					<PasswordInput password={password} setPassword={setPassword} />
					{loginError ? (
						<div
							className='alert alert-warning d-flex align-items-center'
							role='alert'
						>
							<div>{loginError}</div>
						</div>
					) : null}

					<button type='submit' className='btn btn-primary col-12 mb-3 py-3'>
						Submit
					</button>
				</form>
				<p className='new-user'>
					Forgot your password? <Link to='/password-reset'>Reset password</Link>
				</p>
			</div>
		</>
	);
};

export default Login;
