import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { development } from "lib/paths";

// Firebase configuration
var firebaseConfig = development
  ? {
      // Development
      // apiKey: 'AIzaSyCkyyrUk-DOWbHavfhfLlKMw5P4lzrZwhU',
      // authDomain: 'defeat-diabetes.firebaseapp.com',
      // databaseURL: 'https://defeat-diabetes.firebaseio.com',
      // projectId: 'defeat-diabetes',
      // storageBucket: 'defeat-diabetes.appspot.com',
      // messagingSenderId: '364000715175',
      // appId: '1:364000715175:web:8e5a5195ae01230834d682'

      //HiPPA Development
      apiKey: "AIzaSyB-fNdRSdqQszczFF7E6r9OIp40Vxq6Qi8",
      authDomain: "defeat-diabetes---hipaa---uat.firebaseapp.com",
      projectId: "defeat-diabetes---hipaa---uat",
      storageBucket: "defeat-diabetes---hipaa---uat.appspot.com",
      messagingSenderId: "477977484392",
      appId: "1:477977484392:web:f7d87d529bcc67a0daafdd",
    }
  : {
      // Production
      apiKey: "AIzaSyAXt7Aknav5nZmTd3zCqcqslFBfGYjV6vA",
      authDomain: "defeat-diabetes---prod.firebaseapp.com",
      databaseURL: "https://defeat-diabetes---prod.firebaseio.com",
      projectId: "defeat-diabetes---prod",
      storageBucket: "defeat-diabetes---prod.appspot.com",
      messagingSenderId: "437212800252",
      appId: "1:437212800252:web:66d7ee788866c095bee379",
    };

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
