/* eslint-disable jsx-a11y/anchor-is-valid */
import { validateSubscriptionPlan } from "lib/accounts/index";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import { cancelSubscriptionPlan } from "lib/accounts/index";
import {
	capitaliseFirstLetter,
	logMessage,
	showErrorToast,
	showSuccessMessage,
	getInterval,
} from "lib/helpers/helperFunctions";
import Modal from "components/Modal";
import { development } from "lib/paths";
import {
	renewSubscriptionPlan,
	verifyCustomerPayment,
} from "lib/accounts/index";
export const CustomerAccount = () => {
	const {
		transactionReceipt,
		subscriptionPlan,
		firstName,
		stripeCustomer,
		email,
	} = useSelector((state) => state.customer.customer);
	const customerUid = useSelector((state) => state.customer.customerUid);
	const [subscriptionDetails, setSubscriptionDetails] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [reloadBuddy, setReloadBuddy] = useState(false);
	const [cancelImmediately, setCancelImmediately] = useState(false);
	const [isError, setisError] = useState(false);
	useEffect(() => {
		setisError(false);
		setSubscriptionDetails({});
		validateSubscriptionPlan(transactionReceipt).then((response) => {
			const isError = response?.data?.error?.ex?.statusCode === 404 && response?.data?.error?.ex?.code === "resource_missing";
			setisError(isError);
			if (transactionReceipt?.startsWith("I-")) {
				const serviceDetails = {
					...response,
					product: response?.data?.items ? getPaypalProductDetail(response.data.items, response.data.items?.billing_cycles) : {}
				};
				console.log("subscriptionPlan", subscriptionPlan);
				handleSubscriptionDetails(subscriptionPlan, serviceDetails);
			} else {
				const serviceDetails = {
					...response,
					product: response?.data?.items ? getProductDetail(response.data.items[0]?.plan, response.data.items[0]?.price) : {}
				};
				handleSubscriptionDetails(subscriptionPlan, serviceDetails);
			}
		}).catch((err) => {
			setisError(true);
		});
	}, [reloadBuddy, email]);

	const handleSubscriptionDetails = (subscriptionPlan, serviceDetails) => {
		if (subscriptionPlan === "Free access")
			serviceDetails.service = "Free Access";
		else if (transactionReceipt?.startsWith("{"))
			serviceDetails.service = "Google";
		else if (transactionReceipt?.startsWith("sub"))
			serviceDetails.service = "Stripe";
		else if (transactionReceipt?.startsWith("I-"))
			serviceDetails.service = "PayPal";
		else serviceDetails.service = "Apple";		
		console.log("serviceDetails", serviceDetails);
		setSubscriptionDetails(serviceDetails);
	};


	const handleCancelSubscription = (cancelImmediately) => {
		cancelSubscriptionPlan(transactionReceipt, cancelImmediately).then(
			(res) => {
				toggleModal();
				if (res?.data?.error) showErrorToast(res?.data?.error?.message);
				else {
					showSuccessMessage("Subsciption is Cancelled");
					setReloadBuddy("Subscription Cancelled! Go!");
				}
			}
		);
	};

	const getProductDetail = (planObj, priceObj) => {
		if (!(planObj && priceObj)) {
			return {}
		}
		return {
			id: priceObj.id,
			name: `${planObj.interval_count} ${capitalizeFirstLetter(planObj.interval)}`,
			durationMonths: calculateDurationInMonths(planObj.interval_count, planObj.interval),
			price: convertUnitAmountToAmount(priceObj.unit_amount_decimal),
			billingCycle: planObj.interval === "year" ? "annually" : planObj.interval,
			product: priceObj.product,
			trialPeriod: planObj.trial_period_days,
			interval: getInterval(planObj.interval_count, planObj.interval),
		}
	}

	const getPaypalProductDetail = (planObj, priceObj) => {
		if (!(planObj && priceObj)) {
			return {}
		}
		return {
			id: planObj.id,
			name: `${capitalizeFirstLetter(planObj.name)}`,
			durationMonths: calculateDurationInMonths(priceObj[0].frequency?.interval_count, priceObj[0].frequency?.interval_unit),
			price: priceObj[0].pricing_scheme?.fixed_price?.value,
			billingCycle: priceObj[0].frequency?.interval_unit === "YEAR" ? "annually" : priceObj[0].frequency?.interval_unit,
			product: planObj.product_id,
			trialPeriod: priceObj[1].frequency?.interval_count,
			interval: getInterval(priceObj[0].frequency?.interval_count, priceObj[0].frequency?.interval_unit),
		}
	}

	const capitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const convertUnitAmountToAmount = (unitAmountDecimal) => {
		const amount = unitAmountDecimal / 100;
		return amount.toFixed(2);
	}

	const calculateDurationInMonths = (interval_count, interval) => {
		return interval_count
	}


	const toggleModal = () => setModalVisible(!modalVisible);

	const handlePrimaryModalButton = () =>
		handleCancelSubscription(cancelImmediately);

	const handleRenewSubscription = () => {
		verifyCustomerPayment(stripeCustomer).then((customerDetails) => {
			if (customerDetails.paymentExist || development) {
				if (development)
					logMessage("made it through payment check because in dev mode");
				renewSubscriptionPlan(
					transactionReceipt,
					stripeCustomer,
					customerUid,
					subscriptionPlan
				).then((subscriptionResponse) => {
					if (subscriptionResponse?.data?.error)
						showErrorToast(subscriptionResponse?.data?.error?.message);
					else setReloadBuddy("Payment Updated GO!");
				});
			} else logMessage("In Valid Payment");
		});
	};
	//Conditional UI Rendering
	const renderBadgeColor = () => {
		if (!subscriptionDetails?.service) return 'danger';

		if (subscriptionDetails.service === 'Stripe') {
			if (
				subscriptionDetails?.data?.status === 'active' &&
				subscriptionDetails?.data?.cancelled === true
			) { return 'warning'; }
			else if (
				subscriptionDetails?.data?.status === 'active') { return 'success'; }
			else return 'danger';
		} else {
			if (subscriptionDetails?.data?.status === 'active') return 'success';
			if (subscriptionDetails?.data?.status === 'canceled') return 'warning';
			return 'danger';
		}
	}

	const renderStatusText = () => {
		if (subscriptionDetails?.service === 'Stripe') {
			if (subscriptionDetails?.data?.status === 'canceled') return 'Expired';
			else if (subscriptionDetails?.data?.cancelled) return 'Cancelled';
			else return capitalizeFirstLetter(subscriptionDetails?.data?.status);
		} else {
			return capitalizeFirstLetter(subscriptionDetails?.data?.status);
		}
	};

	const renderRenewInstructions = () => {
		if (subscriptionDetails?.service === 'Stripe')
			return `pressing the “Cancel Subscription” button
										below`;
		else if (subscriptionDetails?.service === 'Google')
			return `visiting the Google Play Store`;
		else return `visiting the App Store`;
	};

	const renderProductDetails = () => {
		if (subscriptionDetails?.product?.name)
			return `${subscriptionDetails?.product?.name} - $${subscriptionDetails?.product?.price} `;
		else if (subscriptionDetails?.service !== 'Stripe')
			return capitaliseFirstLetter(subscriptionPlan.replace(/_/g, ' '));
	};

	const renderDate = () => {
		return new Date(subscriptionDetails?.data?.endDate * 1).toLocaleDateString(
			typeof window === 'undefined' ? 'en-AU' : window.navigator.language,
			{
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}
		);
	};

	const calculateDiscountedPrice = () => {
		if (subscriptionDetails?.data?.discount) {
			const discount = subscriptionDetails?.data?.discount;
			if (discount.percentOff) {
				const amount = subscriptionDetails?.product?.price;
				const discountPercent = discount?.percentOff;
				const discountAmount = (amount * discountPercent) / 100;
				const discountedPrice = amount - discountAmount;
				return Number(discountedPrice.toFixed(2));
			} else if (discount.amountOff) {
				const amount = subscriptionDetails?.product?.price - convertUnitAmountToAmount(discount.amountOff)
				return Number(amount.toFixed(2));
			} else {
				return +subscriptionDetails?.product?.price
			}
		}
		return subscriptionDetails?.product?.price
	}

	const renderMembershipFeeMessage = () => {
		let feeMessage;
		if (subscriptionDetails?.service === 'Stripe') {
			if (subscriptionDetails?.data?.discount) {
				const discountInfo = subscriptionDetails?.data?.discount;
				const discountType = discountInfo?.percentOff ? 'percentage' : 'fixed';
				const discountValue = discountInfo?.percentOff ? `${discountInfo?.percentOff}% off` : `$${convertUnitAmountToAmount(discountInfo?.amountOff)}`;

				feeMessage = (
					<p className="fs-6">
						User will be charged a recurring <span className="fw-bold"> ${calculateDiscountedPrice()} </span> membership fee ({discountValue} {discountType} discount). This charge will automatically renew every {subscriptionDetails?.product?.durationMonths} {subscriptionDetails?.product?.interval}.
					</p>
				);
			} else {

				feeMessage = (
					<p className="fs-6">
						User will be charged a recurring ${calculateDiscountedPrice()} membership fee. This charge will automatically renew every {subscriptionDetails?.product?.durationMonths} {subscriptionDetails?.product?.interval}.
					</p>
				);
			}
		}
		return feeMessage;
	};

	const renderRenewalInstructions = () => {
		if (subscriptionDetails.service === 'Stripe' && subscriptionDetails.data.status === 'canceled') {
			return (
				<>
					<p style={{ fontSize: '1rem' }}>
						Your subscription has expired, please renew for membership access!
					</p>
					<p style={{ fontSize: '1rem' }}>
						By restarting your subscription, you agree to a recurring ${subscriptionDetails.product.price} membership fee. This first charge occurs immediately, then automatically renews every {subscriptionDetails.product.durationMonths} {subscriptionDetails.product.interval}.
					</p>
				</>
			);
		} else if (subscriptionDetails.service !== 'Stripe' && subscriptionDetails.data.status === 'canceled') {
			return (
				<>
					<p style={{ fontSize: '1rem' }}>
						Your subscription has been cancelled, please renew for membership access!
					</p>
					<p style={{ fontSize: '1rem' }}>
						By renewing your subscription, you consent to a recurring membership fee. The initial charge will be processed immediately, and subsequent charges will be applied on a recurring basis.
					</p>
				</>
			);
		}
		return null;
	};

	return (
		<div className='container align-center text-align-center rounded bg-white mt-4 col-md-8 col-lg-8'>
			{isError &&
				<Alert key="danger" variant="danger">
					The subscription details have been deleted or the subscription has expired.
				</Alert>
			}
			<ul className='list-group list-group-flush'>
				{subscriptionDetails?.service ? (
					<>
						<li className='list-group-item p-0 border-0'>
							<div className='d-flex justify-content-between align-items-center mb-3'>
								<p className='text-primary fs-4 mb-0'>
									{renderProductDetails()}
								</p>
								<p
									className={`badge rounded-pill mb-0 py-2 px-3 bg-${renderBadgeColor()}`}
								>
									{renderStatusText()}
								</p>
							</div>

							{subscriptionDetails?.data?.status === 'active' &&
								!subscriptionDetails?.data?.cancelled && (
									<>
										{renderMembershipFeeMessage()}
									</>
								)}

							<p style={{ fontSize: '1rem' }}>
								Subscriptions automatically renew at least 24 hours prior to the
								end of the current period, user can update or cancel
								subscription by {renderRenewInstructions()}. For more details
								see our{' '}
								<Link to='/terms-and-conditions'>
									<a style={{ fontSize: '1rem' }}>Terms and Conditions</a>
								</Link>{' '}
								and{' '}
								<Link to='/privacy-policy'>
									<a style={{ fontSize: '1rem' }}>Privacy Policy</a>
								</Link>
							</p>

							{subscriptionDetails?.data?.status === 'active' &&
								subscriptionDetails?.data?.cancelled && (
									<>
										<p style={{ fontSize: '1rem' }}>
											Your subscription has been cancelled, but you still have
											access to membership features until the end date of your
											paid subscription period.
										</p>
										<p style={{ fontSize: '1rem' }}>
											If you wish to resume your subscription, click the 'Resume
											Subscription' button below, and your membership will
											continue without interruption.
										</p>
									</>
								)}
							{renderRenewalInstructions()}
						</li>
						{subscriptionDetails?.data?.endDate ? (
							<li className='list-group-item p-0 mb-3'>
								<p
									className='mb-2'
									style={{ fontSize: '1rem', fontWeight: 600 }}
								>
									{subscriptionDetails.data.status === 'active' &&
										!subscriptionDetails.data.cancelled &&
										'Renews on:'}
									{subscriptionDetails.data.status === 'active' &&
										subscriptionDetails.data.cancelled &&
										'Access ends on:'}
									{subscriptionDetails.data.status === 'canceled' &&
										'Access ended on:'}
									{subscriptionDetails.data.status === 'trialing' &&
										!subscriptionDetails.data.cancelled &&
										'Renews on:'}
									{subscriptionDetails.data.status === 'trialing' &&
										subscriptionDetails.data.cancelled &&
										'Access ends on:'}
									{subscriptionDetails.data.status === 'expired' &&
										'Expired on:'}
									{!subscriptionDetails.data.status ? 'Access ends on' : null}
								</p>
								<p className='text-primary fs-4'>{renderDate()}</p>
							</li>
						) : null}

						<li className='list-group-item d-flex justify-content-between align-items-center p-0'>
							<p className='mb-0' style={{ fontSize: '1rem' }}>
								{subscriptionDetails?.service
									? `${firstName}'s membership is managed by ${subscriptionDetails?.service}`
									: 'No data found'}
							</p>
							{subscriptionDetails &&
								subscriptionDetails?.service === 'Stripe' &&
								(subscriptionDetails?.data?.status === 'active' ||
									subscriptionDetails?.data?.status === 'trialing') &&
								subscriptionDetails?.data?.cancelled === false && (
									<>
										<button
											className='btn btn-outline-danger'
											onClick={toggleModal}
										>
											Cancel Subscription
										</button>
										{development ? (
											<button
												className='btn btn-outline-danger'
												onClick={() => {
													setCancelImmediately(true);
													toggleModal();
													// Allows for immediate cancellation
												}}
											>
												Cancel Immediately
											</button>
										) : null}
									</>
								)}
							{/* {subscriptionDetails &&
                subscriptionDetails.service === "Stripe" &&
                subscriptionDetails.data.status === "canceled" && (
                  <button
                    className="btn btn-warning"
                    onClick={handleRenewSubscription}
                  >
                    Renew Subscription
                  </button>
                )} */}
							{/* {subscriptionDetails &&
                subscriptionDetails.service === "Stripe" &&
                subscriptionDetails.data.status === "active" &&
                subscriptionDetails.data.cancelled === true && (
                  <button
                    className="btn btn-warning"
                    onClick={handleRenewSubscription}
                  >
                    Resume Subscription
                  </button>
                )} */}
						</li>
					</>
				) : (
					<li className='list-group-item mb-3 py-5 align-items-center justify-content-center d-flex flex-column'>
						<div
							className='spinner-border text-primary mx-auto my-3'
							role='status'
						>
							<span className='visually-hidden'>
								Loading subscription details...
							</span>
						</div>
						<span>Loading subscription details...</span>
					</li>
				)}
			</ul>
			<Modal
				title='Cancel Subscription'
				body={`Are you sure that you want to cancel ${firstName}'s Subscription Plan?`}
				primaryButton='Cancel Plan'
				secondaryButton='Go Back'
				isVisible={modalVisible}
				secondaryButtonFunction={toggleModal}
				primaryButtonFunction={handlePrimaryModalButton}
				primaryButtonclassName='btn btn-outline-danger'
				secondaryButtonclassName='btn btn-outline-secondary'
			/>
		</div>
	);
};

export default CustomerAccount;
