import {
  updateUser,
  getUpdateUserData,
  getUserSubcollection,
} from "lib/firebase/db";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableText from "components/EditableTextComponent";
import {
  setCustomer,
  setCustomerVitals,
} from "features/customer/customerSlice";
import Button from "react-bootstrap/Button";
import firebase from "firebase";
import {
  showErrorToast,
  formatDate,
  decryptData,
} from "../../lib/helpers/helperFunctions";
import ListModel from "../../components/ListModel";
import PreviousSubscriptionModel from "../../components/PreviousSubscriptionModel";
import { SUPER_ADMIN,ADMIN } from "lib/constants/userTypes";
import { HBA1C, WEIGHT, WAIST } from "lib/constants/vitals"

function CustomerOtherDetails() {
  const dispatch = useDispatch();

  const {
    subscriptionPlan,
    transactionReceipt,
    stripeCustomer,
    subscriptionExpiry,
    wordpressUserId,
    previousSubscriptions = [],
    transactionId,
  } = useSelector((state) => state?.customer?.customer);

  const { hba1c, weight, waist } = useSelector((state) => state?.customer.vitals);
  const { customerClaims } = useSelector((state) => state?.customer);
  const [usersubscriptionPlan, setsubscriptionPlan] = useState(subscriptionPlan);
  const [editedTransactionId, setEditedTransactionId] = useState(transactionId);

  const [userTransactionReceipt, setTransactionReceipt] = useState(transactionReceipt);
  const [userStripeCustomer, setStripeCustomer] = useState(stripeCustomer);
  const [userSubscriptionExpiry, setSubscriptionExpiry] = useState(formatDate(subscriptionExpiry));

  const [modalData, setModalData] = useState([]);
  const customerUid = useSelector((state) => state.customer.customerUid);
  const [switchVisible, setSwitchVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ListTitle, setListTitle] = useState("PHI Date");
  const [previousSubscriptionModelvisible, SetPreviousSubscriptionModelVisible] = useState(false);
  const [subCollectionName, SetSubCollectionName] = useState(null);

  useEffect(() => {
    setEditedTransactionId(transactionId);
    setsubscriptionPlan(subscriptionPlan);
    setTransactionReceipt(transactionReceipt);
    setStripeCustomer(stripeCustomer);
    setSubscriptionExpiry(formatDate(subscriptionExpiry));

    fetchCustomerVitals(customerUid, [HBA1C, WEIGHT, WAIST]).then(vitalsData => {
      vitalsData.forEach(({ key, data }) => dispatch(setCustomerVitals({ key, data })));
    });

    if (customerClaims?.admin === SUPER_ADMIN || customerClaims?.admin === ADMIN ) setSwitchVisible(true);
  }, [customerUid]);

  const handleTransactionIdInputChange = (transactionId) => {
    setEditedTransactionId(transactionId)
  }

  const handleSubscriptionPlanInput = (subscriptionPlan) =>
    setsubscriptionPlan(subscriptionPlan);
  const handleTransactionReceiptInput = (transactionReceipt) =>
    setTransactionReceipt(transactionReceipt);
  const handleStripeCustomerInput = (stripeCustomer) =>
    setStripeCustomer(stripeCustomer);
  const handleSubscriptionExpiryInput = (subscriptionExpiry) =>
    setSubscriptionExpiry(subscriptionExpiry);

  const handleSubscriptionPlanUpdation = () => {
    if (usersubscriptionPlan !== subscriptionPlan) {
      updateUser(customerUid, "subscriptionPlan", usersubscriptionPlan);
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    }
  };
  const handleTransactionReceiptUpdation = () => {
    if (userTransactionReceipt !== transactionReceipt) {
      updateUser(customerUid, "transactionReceipt", userTransactionReceipt);
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    }
  };

  const handleTransactionIDUpdation = () => {
    if (editedTransactionId !== transactionId) {
      updateUser(customerUid, "transactionId", editedTransactionId);
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    }
  };


  const handleStripeCustomerUpdation = () => {
    if (userStripeCustomer !== stripeCustomer) {
      updateUser(customerUid, "stripeCustomer", userStripeCustomer);
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    }
  };
  const handleSubscriptionExpiryUpdation = () => {
    if (userSubscriptionExpiry !== subscriptionExpiry) {
      updateUser(
        customerUid,
        "subscriptionExpiry",
        firebase.firestore.Timestamp.fromDate(new Date(userSubscriptionExpiry))
      );
      getUpdateUserData(customerUid).then((updatedUser) => {
        dispatch(setCustomer(updatedUser));
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setModalData([]);
    fetchCustomerVitals(customerUid, [HBA1C, WEIGHT, WAIST]).then(vitalsData => {
      vitalsData.forEach(({ key, data }) => dispatch(setCustomerVitals({ key, data })));
    });
  };

  // Combine getUserSubcollection calls into a single function
  const fetchCustomerVitals = async (customerUid, subCollectionNames) => {
    const promises = subCollectionNames.map(subCollectionName =>
      getUserSubcollection(customerUid, subCollectionName).then(data => ({
        key: subCollectionName,
        data
      }))
    );
    return Promise.all(promises);
  };

  const handlePreviousSubscriptionCancel = () => {
    SetPreviousSubscriptionModelVisible(false);
  };

  const showModel = (modelOpenFor) => {
    SetSubCollectionName(modelOpenFor);
    setModalData([]);
    if (modelOpenFor === HBA1C) {
        setModalData(
          hba1c?.map((r, index) => {
            return {
              key: index,
              date: formatDate(r.date),
              value: decryptData(r.value),
              userId: r.userId,
              id: r.id,
              subCollectionName: modelOpenFor,
            };
          })
        );
        setVisible(true);
        setListTitle("hba1c Data");
    } else if (modelOpenFor === WEIGHT) {
           setModalData(
          weight?.map((r, index) => {
            return {
              key: index + 1,
              date: formatDate(r.date),
              value: decryptData(r.value),
              userId: r.userId,
              id: r.id,
              subCollectionName: modelOpenFor,
            };
          })
        );
        setVisible(true);
        setListTitle("Weight Data");
      } else if (modelOpenFor === WAIST) {
        setModalData(
          waist?.map((r, index) => {
            return {
              key: index,
              date: formatDate(r.date),
              value: decryptData(r.value),
              userId: r.userId,
              id: r.id,
              subCollectionName: modelOpenFor,
            };
          })
        );
        setVisible(true);
        setListTitle("Waist Data");
      } else {
      setModalData([]);
      setVisible(false);
      setListTitle("PHI Data");
      showErrorToast("Something went wrong! please try again");
    }
  };

  return (
    <>
      <div className="container align-center text-align-center rounded bg-white mt-3 ml-1 col-md-8 col-lg-8">
        <div className="mt-4">
          {/* <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="text-right">Other Details</h4>
          </div> */}

          <div className="row mt-2">
            <EditableText
              className="col-md-12 col-lg-6"
              text={customerUid ? customerUid : "N/A"}
              label={"UserId"}
              readOnly={true}
              setValue={false}
              type="text"
              placeholder="UserId"
            />
            <EditableText
              className="col-md-12 col-lg-6"
              text={wordpressUserId ? wordpressUserId : "N/A"}
              label={"Wordpress UserId"}
              type="text"
              readOnly={true}
              setValue={false}
              placeholder="Wordpress UserId"
            />
          </div>

          <div className="row mt-2">
            <EditableText
              className="col-md-12 col-lg-6"
              text={usersubscriptionPlan ? usersubscriptionPlan : "N/A"}
              label={"Subscription Plan"}
              placeholder="Subscription Plan"
              setValue={handleSubscriptionPlanUpdation}
              onChange={(e) => handleSubscriptionPlanInput(e.target.value)}
            />
            <EditableText
              className="col-md-12 col-lg-6"
              text={userSubscriptionExpiry ? userSubscriptionExpiry : "N/A"}
              label={"Subscription Expiry"}
              type="datetime-local"
              placeholder="Subscription Expiry"
              setValue={handleSubscriptionExpiryUpdation}
              onChange={(e) => handleSubscriptionExpiryInput(e.target.value)}
            />
          </div>

          <div className="row mt-2">
            <EditableText
              className="col-md-12 col-lg-6"
              text={userStripeCustomer ? userStripeCustomer : "N/A"}
              label={"Stripe Customer"}
              placeholder="Stripe Customer ID"
              setValue={handleStripeCustomerUpdation}
              onChange={(e) => handleStripeCustomerInput(e.target.value)}
            />

          </div>

          <div className="row mt-2">
            <EditableText
              // className="col-md-12 col-lg-6"
              name='TransactionId'
              text={editedTransactionId ? editedTransactionId : "N/A"}
              label={"Transaction Id"}
              placeholder="Transaction Id"
              setValue={handleTransactionIDUpdation}
              onChange={(e) => handleTransactionIdInputChange(e.target.value)}
              textarea={true}
            />

          </div>

          <div className="row mt-2">
            <EditableText
              text={userTransactionReceipt ? userTransactionReceipt : 'N/A'}
              type={'text'}
              label={"Transaction Receipt"}
              placeholder="Transaction Receipt"
              setValue={handleTransactionReceiptUpdation}
              onChange={(e) => handleTransactionReceiptInput(e.target.value)}
              textarea={true}
            />
          </div>
        </div>

        <div className="row mt-3 mb-2">
          {switchVisible && (
            <>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <Button
                  className="non-focus-btn mt-3"
                  variant="primary"
                  onClick={() => showModel(HBA1C)}
                >
                  Show hba1c
                </Button>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-3">
                <Button
                  className="non-focus-btn mt-3"
                  variant="primary"
                  onClick={() => showModel(WEIGHT)}
                >
                  Show Weight
                </Button>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-3">
                <Button
                  className="non-focus-btn mt-3"
                  variant="primary"
                  onClick={() => showModel(WAIST)}
                >
                  Show Waist
                </Button>
              </div>
            </>
          )}

          <div className="col-sm-6 col-md-6 col-lg-3">
            <Button
              className="non-focus-btn mt-3"
              variant="primary"
              onClick={() => SetPreviousSubscriptionModelVisible(true)}
            >
              Previous Subscriptions
            </Button>
          </div>
        </div>
        <div>
          <ListModel
            visible={visible}
            handleCancel={handleCancel}
            body={modalData}
            title={ListTitle}
            userId={customerUid}
            subCollectionName={subCollectionName}
          />
        </div>
        <div>
          <PreviousSubscriptionModel
            visible={previousSubscriptionModelvisible}
            handleCancel={handlePreviousSubscriptionCancel}
            body={previousSubscriptions}
            userId={customerUid}
          />
        </div>
      </div>
    </>
  );
}

export default CustomerOtherDetails;
