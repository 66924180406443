import { logMessage } from 'lib/helpers/helperFunctions';
import firebase from './firebase';
firebase.firestore().settings({ experimentalForceLongPolling: true });
const db = firebase.firestore();
if (process.env.REACT_APP_EMULATOR === 'true') {
	process.env.REACT_APP_EMULATOR && db.useEmulator('localhost', 8080);
}

export { db };

export const updateUser = (userId, key, data) => {
	logMessage(`userId:- ${userId}, key :- ${key}, data :- ${data}`)
	db.collection('Users')
		.doc(userId)
		.update({
			[key]: data
		})
		.then(() => logMessage('Updated'))
		.catch((err) => logMessage(err));
};

export const addOrUpdateSubCollection = (userId, collectionName, collectionId , data) => {
	logMessage(`userId:- ${userId}, collectionName :- ${collectionName}, collectionId :- ${collectionId}, data :- ${data}`)
	if(collectionId){
		db.collection('Users')
		.doc(userId)
		.collection(collectionName)
		.doc(collectionId)
		.set(data)
		.then(() => logMessage('Sub-collection Updated'))
		.catch((err) => logMessage(err));
	}else{
		db.collection('Users')
		.doc(userId)
		.collection(collectionName)
		.add(data)
		.then(() => logMessage('New Data added'))
		.catch((err) => logMessage(err));
	}
	
};

export const deleteSubCollectionItem = async (userId, collectionName, subCollectionId ) => {
	return db.collection('Users').doc(userId).collection(collectionName).doc(subCollectionId).delete();
}

export const getUpdateUserData = async (uid) => {
	const data = db.collection('Users').doc(uid);
	const userData = await data.get().then((doc) => {
		if (doc.exists) {
			return doc.data();
		}
	});
	return userData;
};

export const getUserSubcollection = async (uid, subCollectionName) =>{
	const data = db.collection("Users").doc(uid).collection(subCollectionName).orderBy('date','desc')
	return await data.get().then(querySnapshot => {
		return querySnapshot.docs.map((doc) => {
			return {
				userId : uid,
				id: doc.id,
				...doc.data(),
			}
		});
	}).catch((err) => logMessage(err))

}