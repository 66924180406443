import { endpointCancelStripeSubscription } from "../firebase/endpoints";
import axios from "axios";
import { handlerAxiosErrors } from "lib/helpers/helperFunctions";
import { axiosConfig } from "lib/constants/axiosConstant";

export const cancelSubscriptionPlan = async (
  transactionReceipt,
  cancelImmediately
) => {
  const requestBody = {
    sub: transactionReceipt,
    cancelImmediately,
  };
  let cancelSubscription = await axios
    .post(endpointCancelStripeSubscription, requestBody, axiosConfig)
    .catch((err) => handlerAxiosErrors(err));
  return cancelSubscription;
};
