import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items, className  }) => {
    return (
        <div className={className}>
            <Breadcrumb>
                {items.map((item, index) => (
                    <Breadcrumb.Item key={index}>
                        {
                            item.href ? <Link to={item.href}>{item.label}</Link> : item.label
                        }
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </div>
    );
};

export default Breadcrumbs;