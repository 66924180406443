import { development } from "lib/paths";
import { toast } from "react-toastify";
import firebase from "firebase";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const showErrorToast = (message) => {
  toast.error(message, toastOptions);
};

export const showSuccessMessage = (message) => {
  toast.success(message, toastOptions);
};

export const showInfoMessage = (message) => {
  toast.info(message, toastOptions);
};

export const logMessage = (message) => development && console.log(message);

export const handlerAxiosErrors = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("\nerror data: ", error.response.data);
    console.log("\nerror status: ", error.response.status);
    console.log("\nerror header: ", error.response.headers);
    return error.response;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("\nerror request: ", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("\nError message: ", error.message);
  }
  console.log("\nerror config: ", error.config);
  // return error;
};

export const capitaliseFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getGuid = (length = 10) => {
  function makeid(length) {
    var result = [];
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  }

  const guid = makeid(length);

  return guid;
};

export const formatDate = (jsonTimestamp) => {
  if(!jsonTimestamp){
    return null;
  }
  const timestamp = new firebase.firestore.Timestamp(
    jsonTimestamp.seconds,
    jsonTimestamp.nanoseconds
  );
  return dayjs(timestamp.toDate()).format("YYYY-MM-DD HH:mm:ss");
};

export const decryptData = (encryptedHex) => {
  try {
    const fkey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const fiv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IVHEX);
    let bytes = CryptoJS.AES.decrypt(encryptedHex, fkey, { iv: fiv });
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    return "Invalid Data";
  }
};

export const encryptedData = (data) => {
  try {
    const fkey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const fiv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IVHEX);
    const enc = CryptoJS.AES.encrypt(String(data) , fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const final = enc.ciphertext.toString(CryptoJS.enc.Base64)
    return final;
  } catch (err) {
    return "Inavlid Data!";
  }
};

export const getInterval = (duration,interval) => {
  if(duration > 1){
    switch (interval) {
      case 'day':
        return 'days';
      case 'month':
        return 'months';
      case 'year':
        return 'years';
      default:
        return interval; 
    }
  }else{
  return interval
  }
}