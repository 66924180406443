import { Modal, Form, Table, Input, Button } from "antd";
import { useState, useEffect } from "react";
import React from "react";
import {
  EditFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { updateUser } from "lib/firebase/db";
const { TextArea } = Input;
const PreviousSubscriptionModel = ({ visible, handleCancel, body, userId }) => {

  return (
    <Modal
      title="Previous Subscriptions"
      open={visible}
      onCancel={handleCancel}
      aria-labelledby="contained-modal-title-vcenter"
      width={'85%'}
      footer={null}
      className="previousModel"
      style={{
        top: 50,
      }}
      zIndex={99999}
    >
      <div>
        <PreviousSubscriptionTable
          data={body}
          userId={userId}
          modelClose={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default PreviousSubscriptionModel;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 1, maxRows: 3 }}></TextArea>
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const PreviousSubscriptionTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    setData(
      props?.data.map((_, i) => {
        return {
          key: i.toString(),
          ..._,
        };
      })
    );
  }, [props.data]);

  useEffect(() => {
    setEditingKey("");
  },[props.modelClose]);

  const edit = (record) => {
    form.setFieldsValue({
      stripeCustomer: "",
      subscriptionPlan: "",
      transactionId: "",
      transactionReceipt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (dataRow) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => dataRow.key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        updateUser(props?.userId, "previousSubscriptions", newData);
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Stripe Customer",
      dataIndex: "stripeCustomer",
      editable: true,
      sorter: (a, b) => a.date - b.date,
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? text : "N/A"),
      ellipsis: true,
    },
    {
      title: "Subscription Plan",
      dataIndex: "subscriptionPlan",
      editable: true,
      sorter: (a, b) => a.date - b.date,
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? text : "N/A"),
      ellipsis: true,
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      editable: true,
      sorter: (a, b) => a.date - b.date,
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? text : "N/A"),
      ellipsis: true,
    },
    {
      title: "Transaction Receipt",
      dataIndex: "transactionReceipt",
      editable: true,
      sorter: (a, b) => a.date - b.date,
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? text : "N/A"),
      ellipsis: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                style={{
                  marginRight: 5,
                }}
                icon={<CheckCircleFilled />}
                onClick={() => save(record)}
              >
                Save
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseCircleFilled />}
                loading={false}
                onClick={() => cancel()}
              >
                Cancel
              </Button>
            </span>
        ) : (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="primary"
            className="non-focus-btn"
            icon={<EditFilled />}
            loading={false}
            onClick={() => edit(record)}
          >
            Edit
          </Button>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        style={{ 'margin-top' : '1.5rem'}}
      />
    </Form>
  );
};
