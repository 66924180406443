import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


function Scrapbook() {
	const { Title } = Typography;
	const { name } = useSelector(
		(state) => state.customer.customerClaims
	);
	return (
		<div className='App mt-5'>
			<Title level={2} className="mb-3 text-primary fw-semibold text-capitalize">{`Hello, ${name}`}</Title>
			<div className='mt-5 d-flex flex-row justify-content-start'>
				{/* <button
					className='btn btn-primary btn-custom mx-2 my-2'
					onClick={() => {
						databaseCleanup()
							.then((result) => logMessage(result))
							.catch((error) => logMessage(error));
					}}
				>
					Cleanup Database
				</button> */}
				{/* <button
					className='btn btn-primary btn-custom mx-2 my-2'
					onClick={() => {
						createUser()
							.then((result) => console.log(result))
							.catch((error) => console.log(error));
					}}
				>
					Create User
				</button> */}
				{/* <button
					className='btn btn-primary btn-custom mx-2 my-2'
					onClick={() => {
						getCustomer()
							.then((result) => logMessage(result))
							.catch((error) => logMessage(error));
					}}
				>
					Get Non-updated Customers
				</button>
				<button
					className='btn btn-primary btn-custom mx-2 my-2'
					onClick={() => {
						setUpdatedToFalse()
							.then((result) => logMessage(result))
							.catch((error) => logMessage(error));
					}}
				>
					Set updated to False
				</button>
				<button
					className='btn btn-primary btn-custom mx-2 my-2'
					onClick={() => {
						setUpdatedToTrue();
					}}
				>
					Set updated to True
				</button> */}

				<Link className='decoration-none flex-nowrap' to='/super-admin/user/create'>
					<button
						className='btn btn-primary btn-custom me-4 my-2'
					>
						Create User with Free Access
					</button>
				</Link>

				<Link className='decoration-none flex-nowrap' to='/super-admin/block'>
					<button
						className='btn btn-primary btn-custom mx-4 my-2'
					>
						Block Users
					</button>
				</Link>

				<Link className='decoration-none ' to='/super-admin/delete'>
					<button
						className='btn btn-primary btn-custom mx-4 my-2'
					>
						Delete Users
					</button>
				</Link>
			</div>
		</div>
	);
}

export default Scrapbook;

// const [loading, setLoading] = useState(false);
// const [results, setResults] = useState([]);

// const getContactData = () => {
// 	if (!results.length) {
// 		return;
// 	}
// 	const contactData = [];
// 	results.map((result, index) => {
// 		contactData.push({
// 			Email: result.email,
// 			'First Name': result.firstName,
// 			'Last Name': result.lastName
// 		});
// 	});
// 	// console.log(contactData);
// 	saveToFile(arrayToCSV(contactData), 'contacts.csv', 'text/csv');
// };

// const arrayToCSV = (objArray) => {
// 	const array =
// 		typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
// 	let str = `${Object.keys(array[0])
// 		.map((value) => `"${value}"`)
// 		.join(',')}\r\n`;

// 	return array.reduce((str, next) => {
// 		str += `${Object.values(next)
// 			.map((value) => `"${value}"`)
// 			.join(',')}\r\n`;
// 		return str;
// 	}, str);
// };

// const saveToFile = (content, fileName, contentType) => {
// 	var a = document.createElement('a');
// 	var file = new Blob([content], { type: contentType });
// 	a.href = URL.createObjectURL(file);
// 	a.download = fileName;
// 	a.click();
// };

// const sendCustomerData = () => {
// 	let data = results[0];

// 	const addPaidUser = firebase
// 		.app()
// 		.functions('australia-southeast1')
// 		.httpsCallable('addPaidUser');
// 	addPaidUser(data).then((result) => {
// 		console.log(result);
// 	});
// 	console.log('User added to db');
// };

// const loadDataFromFile = () => {
// 	fetch('users.json', {
// 		headers: {
// 			'Content-Type': 'application/json',
// 			Accept: 'application/json'
// 		}
// 	})
// 		.then((response) => {
// 			return response.json();
// 		})
// 		.then((data) => data.length && setResults(data));
// };

// useEffect(() => {
// 	if (loading) {
// 		console.log('checking it out!');
// 		let start = new Date('2021-10-10');
// 		let end = new Date('2021-10-19');
// 		db.collection('Users')
// 			.where('paidUser', '==', true)
// 			.where('created', '>=', start)
// 			.where('created', '<=', end)
// 			.get()
// 			.then((querySnapshot) => {
// 				const data = [];
// 				querySnapshot.forEach((doc) => {
// 					// doc.data() is never undefined for query doc snapshots
// 					doc.data().firstName !== 'Fatima' &&
// 						doc.id !== 'YNlvNBHEmaXL0dzVxPkvjCI5oUJ3' &&
// 						data.push(doc.data());
// 				});
// 				setResults(data);
// 			})
// 			.catch((error) => {
// 				console.log('Error getting documents: ', error);
// 			});
// 		setLoading(false);
// 	}
// }, [loading]);
