import { configureStore } from "@reduxjs/toolkit";
import customerSlice from "features/customer/customerSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload"],
        // Ignore these paths in the state
        ignoredPaths: ["customer.customer", "payload","customer.vitals"],
      },
    }),
  reducer: {
    customer: customerSlice,
  },
});
