import firebase from "./firebase";
import { db } from './db';
import { getGuid } from 'lib/helpers/helperFunctions';

const functions = firebase.app().functions('australia-southeast1');
if (process.env.REACT_APP_EMULATOR === 'true') {
	process.env.REACT_APP_EMULATOR && functions.useEmulator('localhost', 5001);
}

export { functions };

export const updateAdminStatus = async (userId, adminStatus) => {
	return new Promise(async (resolve, reject) => {
		const updateAdmin = functions.httpsCallable('updateAdminStatus');

		updateAdmin({ userId, adminStatus })
			.catch(() => reject('Something went wrong! Try again later!'))
			.then((result) => resolve(result));
	});
};

export const getClaimInfo = (id) => {
	return new Promise(async (resolve, reject) => {
		const getClaims = functions.httpsCallable('getClaimInfo');

		getClaims({ userId: id, claimType: 'admin' })
			.catch(() => reject('Something went wrong! Try again later!'))
			.then((result) => resolve(result));
	});
};

export const updateUserEmail = async (
	uid,
	stripeCustomer,
	oldEmail,
	newEmail,
	paymentMethod
) => {
	return new Promise(async (resolve, reject) => {
		const updateEmail = functions.httpsCallable('updateUserEmail');

		updateEmail({ uid, stripeCustomer, oldEmail, newEmail, paymentMethod })
			.catch((err) => reject('Something went wrong! Try again later!', err))
			.then((result) => resolve(result));
	});
};

export const updateUserPassword = async (userId, newPassword) => {
	return new Promise(async (resolve, reject) => {
		const updatePassword = functions.httpsCallable('updateUserPassword');

		updatePassword({ userId, newPassword })
			.catch((err) => reject('Something went wrong! Try again later!', err))
			.then((result) => resolve(result));
	});
};

// Database Cleanup

export const databaseCleanup = () => {
	return new Promise(async (resolve, reject) => {
		const customerData = true;
		const listId = true;
		const cleanup = functions.httpsCallable('databaseCleanup');
		cleanup({ customerData, listId })
			.catch((error) => reject(error))
			.then((result) => resolve(result));
	});
};

export const setUpdatedToFalse = () => {
	return new Promise(async (resolve, reject) => {
		const collection = db.collection('Users');
		collection
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					collection.doc(doc.id).update({ updated: false });
				});
				resolve('All done!');
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const setUpdatedToTrue = () => {
	const collection = db.collection('Users');

	getCustomer()
		.then((doc) => {
			console.log(doc);
			if (doc.length && doc[0].length > 0) {
				collection.doc(doc[0]).update({ updated: true });
				setUpdatedToTrue();
			}
		})
		.catch((error) => console.log(error));
};

export const getCustomer = () => {
	return new Promise(async (resolve, reject) => {
		db.collection('Users')
			.where('updated', '==', false)
			.limit(1)
			.get()
			.then((querySnapshot) => {
				let data = [];
				querySnapshot.forEach((doc) => {
					data.push(doc.id);
				});
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const createUsers = () => {
	const services = ['stripe', 'google', 'apple'];
	const statuses = ['active', 'cancelled', 'trial', 'expired'];

	return new Promise(async (resolve, reject) => {
		try {
			services.map((service) => {
				statuses.map((status) => {
					db.collection('Users')
						.doc(getGuid(20))
						.set({
							firstName: status,
							lastName: service,
							email: `${status}@${service}.com`,
							updated: false,
							transactionReceipt: ''
						})
						.then((doc) => {
							console.log(doc);
						});
				});
			});
			resolve('All done');
		} catch (error) {
			reject(error);
		}
	});
};


export const createFreeUser = async (requestBody) => {
	const createFreeUserCloudFunction = firebase.app().functions('australia-southeast1').httpsCallable('freeUser');
	return createFreeUserCloudFunction(requestBody);
}

export const blockUsers = async (emails) => {
	const blockUserCloudFunction = firebase.app().functions('australia-southeast1').httpsCallable('disableUsers');
	const requestBody = {
		emails,
	  };
	return blockUserCloudFunction(requestBody);
}

export const blockUnblockUser = async (uid,disableStatus) => {
	const blockUnblockUserCloudFunction = firebase.app().functions('australia-southeast1').httpsCallable('blockOrUnblockUser');
	const requestBody = {
		uid,
		disableStatus
	  };
	return blockUnblockUserCloudFunction(requestBody);
}

export const deleteUsersPermanent = async (emails) => {
	const deleteUserCloudFunction = firebase.app().functions('australia-southeast1').httpsCallable('permanentDeleteUsers');
	const requestBody = {
		emails,
	  };
	return deleteUserCloudFunction(requestBody);
}

export const isUserBlock = async (uid) => {
	const isUserBlockedFunction = firebase.app().functions('australia-southeast1').httpsCallable('isUserBlocked');
	const requestBody = {
		uid,
	  };
	return isUserBlockedFunction(requestBody);
}