import React from "react";

const Modal = ({
  title,
  body,
  primaryButton,
  secondaryButton,
  onPress,
  isVisible,
  primaryButtonFunction,
  secondaryButtonFunction,
  primaryButtonClass,
  secondaryButtonClass,
}) => {
  return (
    <div
      className={`modal fade show ${isVisible ? "d-block" : ""}`}
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div onClick={secondaryButtonFunction} className="backdrop" />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary" id="exampleModalLiveLabel">
              {title}
            </h5>
          </div>
          <div className="modal-body">{body}</div>
          <div className="modal-footer">
            <button
              type="button"
              className={secondaryButtonClass}
              onClick={secondaryButtonFunction}
            >
              {secondaryButton}
            </button>
            <button
              onClick={primaryButtonFunction}
              type="button"
              className={primaryButtonClass}
            >
              {primaryButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
