import React, { useState, useEffect } from "react";
import Navigation from "features/navigation/Navigation";
import "App.scss";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Home from "pages/Home";
import Login from "pages/Login";
import { auth } from "./lib/firebase/auth";
import { getUserDetails } from "./lib/firebase/auth";
import PasswordChange from "pages/PasswordChange";
import PasswordReset from "pages/PasswordReset";
import { checkIfUserIsAdmin } from "./lib/firebase/auth";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCustomerClaims } from "features/customer/customerSlice";
import "react-toastify/dist/ReactToastify.css";
import Customer from 'pages/Customer';
import Scrapbook from 'pages/Scrapbook';
import DeleteOrBlockUsers from 'pages/super-admin/DeleteOrBlockUsers';
import User from 'pages/super-admin/user';
import PageNotFound from "./pages/pageNotFound";
function App() {
	const dispatch = useDispatch();
	const [user, setUser] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState({});

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user !== null) {
				checkIfUserIsAdmin().then((res) => {
					setUser(user);
					dispatch(setCustomerClaims(res));
				});
			} else {
				setUser(false);
			}
			setLoading(false)
		});
		return () => {
		
			unsubscribe();
		};
	});

	useEffect(() => {
		async function getData() {
			if (user) {
				setUserData(await getUserDetails(user));
			} else {
				setUserData({});
			}
		}
		getData();
	}, [user]);

	return (
		!loading ?
			<Router>
				<Navigation auth={user} />
				<div className='main pt-2 container'>
					<Switch>
						<PrivateRoute exact auth={user} path='/'>
							<Home userData={userData} userId={user.uid} />
						</PrivateRoute>
						<PrivateRoute exact auth={user} path='/password-change'>
							<PasswordChange auth={auth} />
						</PrivateRoute>

						<PublicRoute auth={user} path='/login'>
							<Login />
						</PublicRoute>

						<PrivateRoute auth={user} path='/customer'>
							<Customer />
						</PrivateRoute>						
						
						<PrivateRoute auth={user} path='/super-admin/user/:operation'>
						  <User />
						</PrivateRoute>
						
						<PrivateRoute auth={user} path='/super-admin/:operation'>
						  <DeleteOrBlockUsers />
						</PrivateRoute>
						
						<PrivateRoute auth={user} path='/super-admin'>
							<Scrapbook />
						</PrivateRoute>
						
						<PublicRoute auth={user} path='/password-reset'>
							<PasswordReset auth={auth} />
						</PublicRoute>

						<PublicRoute path='**'>
							<PageNotFound />
						</PublicRoute>
					</Switch>
				</div>
				<ToastContainer />
			</Router>
			:

			<div className="loader"></div>
	);
}

const PublicRoute = (props) => {
	const { auth, children, ...rest } = props;
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth && (props.path === "/login" || props.path === "/register") ? (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location },
						}}
					/>
				) : (
					children
				)
			}
		/>
	);
};

const PrivateRoute = (props) => {
	const { auth, children, ...rest } = props;
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export default App;
