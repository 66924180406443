import CustomerAccount from "features/customer/CustomerAccount";
import CustomerDetails from "features/customer/CustomerDetails";
import CustomerOtherDetails from "features/customer/CustomerOtherDetails"
import { capitaliseFirstLetter } from "lib/helpers/helperFunctions";
import React, { useState, useEffect, useCallback } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, Flex, Col, Row, Popconfirm, message, Tooltip } from 'antd';
import { DeleteOutlined, StopOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { blockUnblockUser, deleteUsersPermanent, isUserBlock } from '../lib/firebase/functions';
import { useHistory } from "react-router-dom";
import { SUPER_ADMIN } from "lib/constants/userTypes";

const Customer = () => {
	const [activePage, setActivePage] = useState('customer');
	const navigate = useHistory();
	const [messageApi, contextHolder] = message.useMessage();
	const { firstName, lastName, email, id } = useSelector(
		(state) => state.customer.customer
	);
	const { admin } = useSelector(
		(state) => state.customer.customerClaims
	);

	const alertMessage = (type, content) => {
		messageApi.open({
			type,
			content,
			style: {
				marginTop: '5vh',
			},
		});
	}
	const toggleCutomerPage = () => setActivePage('customer');
	const toggleAccountPage = () => setActivePage('account');
	const toggleCustomerOtherDetailsPage = () => setActivePage('CustomerOtherDetails');
	const [isUserBlockStatus, setIsUserBlockStatus] = useState(false);

	const getUserBlockStatus = useCallback(() => {
		isUserBlock(id).then(resultData => {
			setIsUserBlockStatus(resultData.data.status);
		}).catch(err => {
			console.error(err);
			setIsUserBlockStatus(false);
		});
	}, [id]);
	
	const confirmBlockUser = () => {
		return new Promise((resolve, reject) => {
				blockUnblockUser(id,!isUserBlockStatus).then(resultData => {
					console.log(resultData)
					alertMessage('success', resultData.data.message);
					setIsUserBlockStatus(!isUserBlockStatus);
					resolve(null);
				}).catch(err => {
					console.error(err);
					alertMessage('error', "Something went wrong! Please try again.");
					reject(null);
				})
		});
	};

	const confirmDeleteUser = () => {
		return new Promise((resolve, reject) => {
			if (email) {
				deleteUsersPermanent(new Array(email)).then(resultData => {
					alertMessage('success', resultData.data.message);
					resolve(null);
					navigate.push("/");
				}).catch(err => {
					console.error(err);
					alertMessage('error', "Something went wrong! Please try again.");
					reject(null);
				})
			} else {
				alertMessage('error', "Something went wrong! Please try again.");
				reject(null);
			}
		});
	}

	useEffect(() => {
		getUserBlockStatus()
	}, [getUserBlockStatus])


	return (
		<>
			{contextHolder}
			<div className='container align-center text-align-center rounded bg-white mt-5 col-md-8 col-lg-8'>
				<Row>
					<Col className="d-flex align-items-center" span={24}>
						<div className="me-1">
							<h2 className='text-primary '>{`Customer - ${capitaliseFirstLetter(
								firstName
							)} ${capitaliseFirstLetter(lastName)}`}</h2>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={admin === SUPER_ADMIN ? 18 : 24}>
						<div className='pt-4'>
							<Nav variant='tabs'>
								<Nav.Item>
									<Nav.Link
										className='no-outline'
										onClick={toggleCutomerPage}
										active={activePage === 'customer' ? true : false}
									>
										Details
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link
										className='no-outline'
										onClick={toggleAccountPage}
										active={activePage === 'account' ? true : false}
									>
										Account
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link
										className='no-outline'
										onClick={toggleCustomerOtherDetailsPage}
										active={activePage === 'CustomerOtherDetails' ? true : false}
									>
										Data
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
					</Col>
					{admin === SUPER_ADMIN && <Col span={6} className="d-flex justify-content-around align-items-center" >
						<Flex wrap gap="small">
							{<Popconfirm
								title="Block the user"
								description={`Are you sure you want to ${isUserBlockStatus ? "Unblock" : "Block"} this user?`}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={confirmBlockUser}
							>
								<Tooltip title={isUserBlockStatus ? "Unblock User" : "Block User"}>
									<Button type="primary" ghost icon={<StopOutlined />} >{isUserBlockStatus ? "Unblock" : "Block"}</Button>
								</Tooltip>
							</Popconfirm>
							}

							<Popconfirm
								title="Delete the user"
								description="Are you sure you want to Delete this user permanently?"
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={confirmDeleteUser}
							>
								<Tooltip title="Delete User">
									<Button danger icon={<DeleteOutlined />} >Delete</Button>
								</Tooltip>
							</Popconfirm>
						</Flex>
					</Col>
					}
				</Row>
			</div>
			{activePage === 'customer' ? <CustomerDetails /> : null}
			{activePage === 'account' ? <CustomerAccount /> : null}
			{activePage === 'CustomerOtherDetails' ? <CustomerOtherDetails /> : null}
		</>
	);
};

export default Customer;
