const productionPath = 'https://admin.defeatdiabetes.com.au';
const developmentPath = 'http://localhost:3000';

export const basePath =
	typeof window === 'undefined' ||
	window.location.host !== 'admin.defeatdiabetes.com.au'
		? developmentPath
		: productionPath;

export const development =
	typeof window === 'undefined' ||
	window.location.host !== 'admin.defeatdiabetes.com.au';
