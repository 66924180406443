import { createSlice  } from "@reduxjs/toolkit";
import { HBA1C, WEIGHT, WAIST } from "../../lib/constants/vitals";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
    customerUid: null,
    customerClaims: null,
    vitals:{
      [HBA1C] : [],
      [WEIGHT] : [],
      [WAIST] : [],
    }
  },
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerUid = action.payload;
    },
    setCustomerClaims: (state, action) => {
      state.customerClaims = action.payload;
    },
    setCustomerVitals : (state , action) => {
      const key = action.payload.key
      state.vitals[key] = action.payload.data;
    }
  },

});

export const { setCustomer, setCustomerId, setCustomerClaims,setCustomerVitals } =
  customerSlice.actions;

export default customerSlice.reducer;
