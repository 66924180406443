import { development } from 'lib/paths';

const basePathUAT =
	'https://australia-southeast1-defeat-diabetes---hipaa---uat.cloudfunctions.net';

const basePathProduction =
	'https://australia-southeast1-defeat-diabetes---prod.cloudfunctions.net';

const basePathLocal =
	'http://localhost:5001/defeat-diabetes/australia-southeast1';

const basePath =
	process.env.REACT_APP_EMULATOR === 'true'
		? basePathLocal
		: development
		? basePathUAT
		: basePathProduction;

export const endpointRegister = `${basePath}/register`;
export const endpointSetCustomerPayment = `${basePath}/setCustomerPayment`;
export const endpointCancelStripeSubscription = `${basePath}/cancelStripeSubscription`;
export const endpointVerifyCaptcha = `${basePath}/verifyCaptcha`;
export const endpointCheckEmailAvailable = `${basePath}/checkEmailAvailable`;
export const endpointRenewSubscription = `${basePath}/renewSubscription`;
export const endpointEmailSubscribe = `${basePath}/emailSubscribe`;
export const endpointReceiptValidation = `${basePath}/receiptValidation`;
export const endpointVerifyPromoCode = `${basePath}/verifyPromoCode`;
export const endpointVerifyCustomerPayment = `${basePath}/verifyCustomerPayment`;
